<template>
  <div class="student-detail-sheet">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ titleForm }}</h2>
          <div class="mt-4">
            <v-row 
              no-gutters
              v-for="item in getStatus"
              :key="item.title"
            >
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="2"
              >
                <p>{{ item.title | text }}</p>
              </v-col>
              <v-col
                cols="9"
                sm="9"
                md="9"
                lg="10"
              >
                <p>{{ `: ${item.value}` }}</p>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <form-sheet v-if="isFormSheet" :form="form" :formId="formId" @handleBack="handleBack"/>
          <form-couple-agreement v-if="isCoupleAgreementForm" :formId="formId" @handleBack="handleBack"/>
          <form-health v-if="isHealthForm" :formId="formId" @handleBack="handleBack"/>
          <form-financial-support v-if="isFinancialForm" :formId="formId" @handleBack="handleBack"/>
          <form-recommendation v-if="isRecommendationForm" :prodi="prodi" :formId="formId" @handleBack="handleBack"/>
          <form-file-completion v-if="isCompleteFile" :prodi="prodi" :formId="formId" @handleBack="handleBack"/>
        </v-card>
        <dialog-confirm ref="dialogConfirmSheetForm" :info="info" :action="handleMove"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import FormSheet from '@/components/form/formSheet'
// Constants
import { text } from '@/apps/web/constants/text'
import {
  repentance_story,
  motivation_story,
  calling_faith_story
} from '@/constants/formContent'
import {DASH_DETAIL_REGIST} from '@/constants/pages'
import DialogConfirm from '@/components/common/DialogConfirm'
import FormCoupleAgreement from '@/components/form/formCoupleAgreement'
import FormFileCompletion from '@/components/form/formFileCompletion'
import FormFinancialSupport from '@/components/form/formFinancialSupport'
import FormHealth from '@/components/form/formHealth'
import FormRecommendation from '@/components/form/formRecommendation'
  export default {
    name: 'RegistrationSheet',
    components: {
      FormSheet,
      FormCoupleAgreement,
      FormHealth,
      FormFinancialSupport,
      FormRecommendation,
      FormFileCompletion,
      DialogConfirm
    },
    data () {
      return {
        isRepentanceTestimonyForm: false,
        isFaithCallingForm: false,
        isMotivationForm: false,
        isCoupleAgreementForm: false,
        isHealthForm: false,
        isFinancialForm: false,
        isCompleteFile: false,
        isRecommendationForm: false,
        prodi: '',
        isDone: false,
        info: {
          desc: 'Perubahan yang anda lakukan tidak akan tersimpan, Anda yakin untuk melanjutkan?'
        },
        path: ''
      }
    },
    computed: {
      getStatus() {
        const period = this.$route.query.periode || new Date().getFullYear()
        const prodiTitle = text[`program.studi.${this.prodi}.title`]
        return [{
          title: 'registration.detail.status.program',
          value: prodiTitle
        }, {
          title: 'registration.detail.status.periode',
          value: period
        }]
      },
      form () {
        let item = {...motivation_story}
        item.value = get(this.$store.state, 'motivation_story', '')
        if (this.isRepentanceTestimonyForm) {
          item = {...repentance_story}
          item.value = get(this.$store.state, 'repentance_story', '')
        } else if (this.isFaithCallingForm) {
          item = {...calling_faith_story}
          item.value = get(this.$store.state, 'calling_faith_story', '')
        }
        return item
      },
      titleForm () {
        let title = 'Pernyataan Motivasi dan Tujuan Studi di STT Amanat Agung'
        if (this.isRepentanceTestimonyForm) {
          title = 'Kesaksian Pertobatan dan Keyakinan Keselamatan'
        } else if (this.isFaithCallingForm) {
          title = 'Kesaksian Keyakinan Panggilan Pelayanan'
        } else if (this.isCoupleAgreementForm) {
          title = 'Pernyataan Persetujuan dari Pasangan'
        } else if (this.isHealthForm) {
          title = 'Keterangan Kesehatan'
        } else if (this.isFinancialForm) {
          title = 'Pernyataan Dukungan Finansial'
        } else if (this.isCompleteFile) {
          title = 'Kelengkapan Data'
        } else if (this.isRecommendationForm) {
          title = 'Keterangan Pengisi Formulir Rekomendasi'
        }
        return title
      },
      isFormSheet () {
        const type = this.$route.query.tipe || ''
        const formSheet = ['is_repentance_testimony_form', 'is_faith_calling_form', 'is_motivation_form']
        return formSheet.indexOf(type) !==-1
      },
      formId() {
        return this.$route.query.formId
      },
    },
    beforeRouteEnter (to, from, next) {
      if (from.name === null) {
        next(vm => {
          vm.isDone = true
          vm.$router.replace({
            name: DASH_DETAIL_REGIST,
            query: {
              prodi: vm.$route.query.prodi,
              formId: vm.formId,
              periode: vm.$route.query.periode || new Date().getFullYear()
            }
          })
        })
      } else {
        next()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.isDone) {
        next()
      } else {
        this.path = to.name
        this.$refs.dialogConfirmSheetForm && this.$refs.dialogConfirmSheetForm.show()
      }
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
    },
    created () {
      window.addEventListener('beforeunload', this.handleBeforeUnload)
      this.prodi = this.$route.query.prodi
      const type = this.$route.query.tipe
      if (type === 'is_repentance_testimony_form') {
        this.isRepentanceTestimonyForm = true
      } else if (type === 'is_faith_calling_form') {
        this.isFaithCallingForm = true
      } else if (type === 'is_motivation_form'){
        this.isMotivationForm = true
      } else if (type === 'is_couple_agreement_form') {
        this.isCoupleAgreementForm = true
      } else if (type === 'is_health_form') {
        this.isHealthForm = true
      } else if (type === 'is_financial_support_form') {
        this.isFinancialForm = true
      } else if (type === 'is_complete_file') {
        this.isCompleteFile = true
      } else if (type === 'is_recommendation_form') {
        this.isRecommendationForm = true
      }
    },
    methods: {
      handleBeforeUnload (event) {
        event.preventDefault()
        event.returnValue = ''
      },
      handleBack (val) {
        this.isDone = val
        if (this.isDone) {
          this.$router.replace({
            name: DASH_DETAIL_REGIST,
            query: {
              prodi: this.$route.query.prodi,
              formId: this.formId,
              periode: this.$route.query.periode || new Date().getFullYear()
            }
          }).catch(() => {})
        }
      },
      handleMove () {
        this.isDone = true
        const query = {
          prodi: this.$route.query.prodi,
          formId: this.formId,
          periode: this.$route.query.periode || new Date().getFullYear()
        }
        this.path = this.path ? this.path : DASH_DETAIL_REGIST
        this.$router.replace({
          name: this.path,
          query: this.path === DASH_DETAIL_REGIST ? query : {}
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
.student-detail-sheet {
  font-size: 14px;
  .title-14 {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>