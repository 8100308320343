<template>
  <div class="student-payment">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ 'registration.payment.title' | text }}</h2>
          <div class="mt-4">
            <v-row 
              no-gutters
              v-for="item in getStatus"
              :key="item.title"
            >
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="2"
              >
                <p>{{ item.title | text }}</p>
              </v-col>
              <v-col
                cols="9"
                sm="9"
                md="9"
                lg="10"
              >
                <p>{{ `: ${item.value}` }}</p>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-alert
            dismissible
            type="error"
            class="mt-4"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <validation-observer v-slot="{ invalid }">
            <v-form id="form-payment" @submit.prevent="submitForm">
              <p class="mt-6">{{ getPaymentInfo }}</p>
              <div class="mt-4">
                <p class="title-14 mb-2">Pembayaran Biaya Pendaftaran <span>(Biaya pendaftaran yang sudah dibayarkan tidak dapat dikembalikan)</span></p>
                <v-select
                  v-model="paymentMethod"
                  :items="paymentMethods"
                  :disabled="isHideButton"
                  dense
                  outlined
                ></v-select>
              </div>
              <p v-if="paymentMethod === 'Transfer'">Untuk pembayaran tranfer dapat ke nomor rekening BCA a/c 2533.200.800 a/n Yayasan Amanat Agung Indonesia.</p>
              <div v-else class="student-payment__img mb-2">
                <img width="600" height="400" :src="QRUrl" alt="qris-img">
              </div>
              <validation-provider
                v-slot="{ errors }"
                name="Bukti Pembayaran"
                rules="required"
              >
                <p class="title-14 mb-2">Bukti Pembayaran</p>
                <v-file-input
                  v-model="paymentProof"
                  outlined
                  dense
                  accept=".png, .jpg, .jpeg"
                  :loading="isLoading"
                  :disabled="isHideButton"
                  placeholder="Click here to select your file"
                  :error="errors.length > 0"
                  :error-messages="errors[0]"
                  @change="handleInput"
                ></v-file-input>
                <img v-if="paymentProofPath" width="600" height="400" class="student-payment__img" :src="paymentProofPath" alt="payment-proof-path">
              </validation-provider>
              <div v-if="!isHideButton" class="d-flex justify-end">
                <v-btn 
                  color="primary"
                  :min-width="160"
                  large
                  type="submit"
                  :disabled="invalid"
                  form="form-payment"
                >
                  KIRIM
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card>
      </div>
    </v-container>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
    <dialog-confirm ref="dialogConfirmSheetForm" :info="info" :action="handleMove"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { get } from 'dot-prop'
import { required } from 'vee-validate/dist/rules'
// Utils
import request from '@/utils/request'
import {
  formatNumber
} from '@/utils/common/formatNumber'
import loadscreen from '@/utils/common/loadScreen'
import { getUrl } from '@/utils/common/getUrl'
// Constants
import { text } from '@/apps/web/constants/text'
import {
  API_GET_REGISTRATION_FEE,
  API_UPLOAD,
  API_DASH_PAYMENT_FROM
} from '@/constants/apis'
import {DASH_DETAIL_REGIST} from '@/constants/pages'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT,
} from '@/constants/status'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'
  export default {
    name: 'Payment',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogConfirm,
      DialogInfo
    },
    data () {
      return {
        alertMsg: '',
        isShowAlert: false,
        amount: 0,
        paymentMethod: 'Transfer',
        paymentProof: [],
        paymentProofPath: '',
        QRUrl: 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/qrcode/qr_sttaa.jpeg',
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        isDone: false,
        path: '',
        isLoading: false
      }
    },
    computed: {
      getStatus() {
        const programStudy = this.$route.query.prodi
        const period = this.$route.query.periode || new Date().getFullYear()
        const prodiTitle = text[`program.studi.${programStudy}.title`]
        return [{
          title: 'registration.detail.status.program',
          value: prodiTitle
        }, {
          title: 'registration.detail.status.periode',
          value: period
        }]
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      paymentMethods () {
        // return ['Transfer', 'QRIS']
        return ['Transfer']
      },
      getPaymentInfo () {
        return `Silakan lakukan pembayaran biaya pendaftaran sebesar Rp ${formatNumber(this.amount)} dengan metode pembayaran dibawah.`
      },
      isHideButton () {
         return [INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1
      }
    },
    async created() {
      loadscreen.show()
      window.addEventListener('beforeunload', this.handleBeforeUnload)
      this.isShowAlert = false
      const res = await request(API_GET_REGISTRATION_FEE, {}, {methods: 'GET'})
      if (res.success) {
        this.QRUrl = res.qr_code_url ? res.qr_code_url : 'https://webadmisi-sttaa.sgp1.digitaloceanspaces.com/qrcode/qr_sttaa.jpeg'
        this.amount = res.registration_fee || 0
      } else {
        this.listProgramStudy = this.items
        this.alertMsg = get(res, 'resultInfo.resultMsg', 'Error System')
        this.isShowAlert = true
      }
      const payment_form = get(this.$store.state, 'payment_form', '')
      if (payment_form) {
        this.paymentMethod = get(payment_form, 'payment_method', 'Transfer')
        if (payment_form.receipt_payment_url) {
          const res = await fetch(payment_form.receipt_payment_url, {mode: 'no-cors', 'Access-Control-Allow-Origin': '*'})
          const blobFile = await res.blob()
          const ext = getUrl(payment_form.receipt_payment_url)
          this.paymentProof = new File([blobFile], `file.${ext}`)
          this.paymentProofPath = payment_form.receipt_payment_url        }
      }
      loadscreen.hide()
    },
    beforeRouteEnter (to, from, next) {
      if (from.name === null) {
        next(vm => {
          vm.isDone = true
          vm.$router.replace({
            name: DASH_DETAIL_REGIST,
            query: {
              prodi: get(vm.$route.query, 'prodi', ''),
              formId: get(vm.$route.query, 'formId', ''),
              periode: vm.$route.query.periode || new Date().getFullYear()
            }
          })
        })
      } else {
        next()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.isDone) {
        next()
      } else {
        this.path = to.name
        this.$refs.dialogConfirmSheetForm && this.$refs.dialogConfirmSheetForm.show()
      }
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      handleInput (e) {
        this.isLoading = true
        const formData = new FormData();
        formData.append("file", e);
        request(API_UPLOAD, formData, {content: 'multipart/form-data'}).then(res => {
          if (res.success) {
            this.paymentProofPath = res.path
          } else {
            this.paymentProofPath = ''
            this.paymentProof = []
          }
          this.isLoading = false
        })
      },
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      handleOnSubmit () {
        loadscreen.show()
        const academicYearList = this.$store.state.dashboard.dashAcademicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        
        let params = {
          payment_method: this.paymentMethod,
          receipt_payment_url: this.paymentProofPath
        }
        params.entry_form_id = get(this.$route.query, 'formId', '')
        params.academic_year_id = academicYearId
        request(API_DASH_PAYMENT_FROM, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      handleBack () {
        this.isDone = true
        if (this.isDone) {
          this.$router.go(-1)
        }
      },
      handleMove () {
        this.isDone = true
        const query = {
          prodi: get(this.$route.query, 'prodi', ''),
          formId: get(this.$route.query, 'formId', ''),
          periode: this.$route.query.periode || new Date().getFullYear()
        }
        this.path = this.path ? this.path : DASH_DETAIL_REGIST
        this.$router.replace({
          name: this.path,
          query: this.path === DASH_DETAIL_REGIST ? query : {}
        }).catch(() => {})
      },
      handleBeforeUnload (event) {
        event.preventDefault()
        event.returnValue = ''
      },
    }
  }
</script>

<style lang="scss" scoped>
.student-payment {
  font-size: 14px;
  .title-14 {
    font-weight: bold;
    font-size: 14px;
  }
  &__img {
    width: 300px;
    height: auto;
    img {
      width: -webkit-fill-available;
      height: auto;
    }
  }
}
</style>